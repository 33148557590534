import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import {Header} from "./components/Header/Header";
import {MainScreen} from "./components/MainScreen/MainScreen";
import {Solutions} from "./components/Solutions/Solutions";
import {Divider} from "./components/Divider/Divider";
import {Reviews} from "./components/Reviews/Reviews";
import {Footer} from "./components/Footer/Footer";
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: 'GTM-MWTLH2F9'
}
TagManager.initialize(tagManagerArgs)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Header/>
        <div style={{maxWidth: '430px', margin: '0 auto'}}>
            <MainScreen/>
            <Solutions/>
            <Divider/>
            <Reviews/>
            <Footer/>
        </div>
    </React.StrictMode>
);
