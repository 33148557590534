import styles from './Footer.module.scss';
import google_play_icon from '../../../src/images/google_play_icon.svg';
import app_store_icon from '../../../src/images/app_store_icon.svg';

export const Footer = () => {
    const handleDownloadClick = () => {
        window.open('https://click.lumos-vpn.com/click', '_blank');
    };

    return (
        <>
            <footer className={styles.footer_container} onClick={handleDownloadClick}>
                <div className={styles.footer_title}>Download App Now</div>
                <div className={styles.store_icons_container}>
                    <img src={google_play_icon} alt='google_play_icon'/>
                    <img src={app_store_icon} alt='app_store_icon'/>
                </div>
                <div className={styles.footer_description}>
                    <div>If you need further assistance contact our Support team</div>
                    <a href='mailto:support@lumos.mobi'>support@lumos.mobi</a>
                </div>
            </footer>
            <div className={styles.footer_copyright}>Copyright 2024 © Lumos LLC</div>
        </>
    )
}