import styles from './Solutions.module.scss'
import solutions_icon from '../../../src/images/solutions_icon.svg'
import unlimited_bullet_icon from '../../../src/images/unlimited_bullet_icon.svg'
import flashing_bullet_icon from '../../../src/images/flashing_bullet_icon.svg'
import hide_bullet_icon from '../../../src/images/hide_bullet_icon.svg'
import bypass_bullet_icon from '../../../src/images/bypass_bullet_icon.svg'
import React from "react";
import {BulletDivider} from "../BulletDivider/BulletDivider";

export const Solutions = () => {
    const solutionsData = [
        {
            title: "Unlimited",
            subtitle: "access to content",
            description: "Enjoy unlimited bandwidth to stay connected to the world's best shows, apps, and games!",
            bulletIcon: unlimited_bullet_icon
        },
        {
            title: "Flashing",
            subtitle: "connecting speed",
            description: "VPN Lumos offers unlimited bandwidth for faster loading and no buffering!",
            bulletIcon: flashing_bullet_icon
        },
        {
            title: "Hide",
            subtitle: "your location",
            description: "Get your own unique IP for added security and access to restricted sites, apps, and services!",
            bulletIcon: hide_bullet_icon
        },
        {
            title: "Bypass",
            subtitle: "blocked sites",
            description: "Access any website safely and anonymously!",
            bulletIcon: bypass_bullet_icon
        },
    ];

    return (
        <div className={styles.solutions_container}>
            {solutionsData.map((solution, index) => (
                <SolutionItem key={index} {...solution} />
            ))}
        </div>
    );
};


const SolutionItem = ({ title, subtitle, description, bulletIcon }) => {
    return (
        <>
            <BulletDivider/>
            <div className={styles.solution_icons_wrapper}>
                <img src={solutions_icon} alt='solutions_icon' width={20} height={20}/>
                <img src={bulletIcon} alt='solutions_icon' width={51} height={51}/>
                <img src={solutions_icon} alt='solutions_icon' width={20} height={20}/>
            </div>
            <div>
                <h2>{title}</h2>
                <h3>{subtitle}</h3>
                <p>{description}</p>
            </div>
        </>
    );
};