import styles from './Header.module.scss';
import logo from '../../../src/images/logo.svg';

export const Header = () => {
    const handleDownloadClick = () => {
        window.dataLayer.push({event: 'click_download_button_in_the_header'});
        window.open('https://click.lumos-vpn.com/click', '_blank');

    };

    return (
        <header className={styles.header_container}>
            <div className={styles.logo_container}>
                <img src={logo} alt='logotype' width={31} height={31}/>
                <span>VPN Lumos</span>
            </div>
            <button className={styles.header_button} onClick={handleDownloadClick}>Download</button>

        </header>
    )
}