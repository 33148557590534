import styles from './Reviews.module.scss';
import stars_icon from '../../../src/images/stars_icon.svg'

export const Reviews = () => {
    return (
        <div className={styles.reviews_container}>
            <div className={styles.reviews_title}>Millions of people trust us!</div>
            <ReviewsItem title='by Marshmallow33 - Feb 05, 2024'
                         description='I never have any issues at all using the app, 10/10 do recommend!'/>
            <ReviewsItem title='by Maximus -  Jan 08, 2023'
                         description='Always worked, even on super slow connections (even when my lte was going on and off on a road trip), and solved a very annoying problem of mine!'/>
            <ReviewsItem title='by Pamjudy - Nov 28, 2023'
                         description='It runs in the background with minimal change in the user experience!'/>
        </div>
    )
}

const ReviewsItem = ({title, description}) => {
    return (
        <div className={styles.reviews_item_container}>
            <div className={styles.stars_icon_container}>
                <img src={stars_icon} alt='stars_icon' width={91} height={17}/>
            </div>
            <div className={styles.reviews_item_title}>{title}</div>
            <div className={styles.reviews_item_description}>{description}</div>
        </div>
    )
}
